html {
  min-height: 100%; }

body {
  height: 100%;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(to top, #0691d0ab 0%, #2ca65eed 100%);
  color: white; }

* {
  box-sizing: border-box; }

.center {
  display: flex;
  justify-content: center; }

.flex {
  display: flex; }

#progress-bar {
  width: 200px;
  height: 15px;
  border: 1px solid white;
  border-radius: 2px;
  position: relative; }
  #progress-bar #progress {
    background-color: #1f824c;
    height: 100%; }
  #progress-bar #day-percent-wrapper {
    position: absolute;
    font-size: 10px;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center; }

#day-number {
  font-size: 8px;
  padding-bottom: 2px; }

.current-temp {
  font-size: 30px; }

a:has(div#weatherData) {
  all: unset;
  cursor: pointer; }

#weatherData {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif; }

.min-temp {
  padding-right: 10px; }

.current-temp {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }

.max-temp {
  padding-left: 10px; }

.weather-icon {
  position: relative;
  top: 10px; }

#weatherSection {
  min-height: 90px; }

#search-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  margin-top: 12px; }
  #search-wrapper #search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 2px; }
    #search-wrapper #search-icon path {
      fill: gray; }

input#search {
  display: block;
  width: 350px;
  height: 24px;
  font-size: 14px;
  padding-left: 24px;
  border-radius: 4px;
  border: 1px solid gray; }
  input#search:focus {
    outline: none; }
  input#search:hover {
    box-shadow: 0 1px 12px rgba(32, 33, 36, 0.28); }

.typeahead-results {
  display: block;
  width: 360px;
  font-size: 14px;
  position: absolute;
  margin: 2px auto 0; }
  .typeahead-results .result {
    background: #ffffff;
    color: #000;
    height: 20px;
    padding: 3px;
    border-bottom: 1px solid #eee;
    cursor: pointer; }
    .typeahead-results .result.selected, .typeahead-results .result:hover {
      background: blue;
      color: #ffffff; }

#preact-handle {
  display: flex;
  justify-content: center; }

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px; }

a.nba img {
  margin-top: 10px;
  margin-left: 15px;
  height: 40px;
  width: auto;
  border-radius: 5px;
  transition: transform 500ms; }
  a.nba img:hover {
    transform: scale(1.2); }

.sections {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 15px;
  margin-top: 20px; }

.section {
  background: #071d22;
  color: #d6d6d6;
  padding: 1em;
  box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.07);
  border: 10px solid #d6d6d6;
  border-radius: 4px; }
  .section:hover h1::before,
  .section:hover h1::after {
    opacity: 1;
    transform: translateX(0px); }
  .section h1 {
    display: flex;
    justify-content: center; }
  .section h1::before,
  .section h1::after {
    display: inline-block;
    opacity: 0;
    transition: transform 0.3s, opacity 0.2s; }
  .section h1::before {
    margin-right: 5px;
    content: '-';
    transform: translateX(10px); }
  .section h1::after {
    margin-left: 5px;
    content: '-';
    transform: translateX(-10px); }
  .section a {
    color: #ffffff;
    display: block;
    text-decoration: none;
    padding: 0.1em;
    font-size: 20px; }
    .section a:hover {
      text-decoration: underline;
      font-size: 22px;
      transition: font-size 0.1s; }
  .section .entry {
    display: flex;
    align-items: center;
    min-height: 35px; }
  .section .entry img {
    margin-right: 5px;
    width: 16px;
    height: 16px; }

@media only screen and (min-width: 500px) {
  div.section {
    width: 100%; } }

@media only screen and (min-width: 700px) {
  div.section {
    width: calc(50% - var(--section-margin) * 2); } }

@media only screen and (min-width: 1000px) {
  div.section {
    width: calc(33% - var(--section-margin) * 2); } }

@media only screen and (min-width: 1440px) {
  div.section {
    width: calc(25% - var(--section-margin) * 2); } }

@media only screen and (min-width: 1650px) {
  div.section {
    width: calc(20% - var(--section-margin) * 2); } }
